angular.module 'app'
.controller 'SingleArticleCtrl', ($scope, $http, $state, $sce, $stateParams, config, activeUser) ->
  $scope.ui =
    column: {}
    article: {}
    content_html: null
    show: true

  do findColumn = ->
    $http.get "#{config.api.baseUrl}/cumpus/programa/#{$stateParams.columnId}", {}
    .then (res) ->
      $scope.ui.column = res.data
      $scope.ui.show = false if res.data.type and res.data.type is "2"
    .catch (error) ->
  do findArticle = ->
    $http.get "#{config.api.baseUrl}/cumpus/programa/list",
      params:
        is_content: true
        programa_id: $stateParams.columnId
        group_id: config.user.group_id
    .then (res) ->
      if res.data.data.length > 0
        $scope.ui.article = res.data.data[0]
        if $scope.ui.article.release_time isnt null
          $scope.ui.article.release_time = moment($scope.ui.article.release_time).format 'YYYY-MM-DD'
        # $scope.ui.content_html = $sce.trustAsHtml $scope.ui.article.data.content

        _content = _.clone $scope.ui.article.data.content
        _content = $("<div>#{_content}</div>").html()

        $rows = $("<p>#{_content}</p>")

        $scope.ui.content_html = $sce.trustAsHtml $scope.ui.article.data.content if $rows.find('iframe').toArray().length == 0

        _.each $rows.find('iframe').toArray(), (data, i) ->
          $http.get "#{config.api.baseUrl}/cumpus/programa/list/video",
            params:
              url: $(data).data('src')
          .then (res) ->
            _oldHtml3 = _.unescape $('<p>').append($(data).clone()).html()
            _src = $(data).data('src')
            $(data).removeAttr('data-src')
            _oldHtml = _.unescape $('<p>').append($(data).clone()).html()
            if data.src
              _oldHtml2 = _oldHtml.replace(data.src, "#{config.api.baseUrl}#{res.data.url}")
            _newHtml = _.unescape $('<p>').append($(data).clone()).html()
            $scope.ui.article.data.content = $scope.ui.article.data.content.replace(_oldHtml3, "<p style='text-align: center;'>#{_oldHtml2}</p>").replace(data.src, "#{config.api.baseUrl}#{res.data.url}") if data.src
            $scope.ui.content_html = $sce.trustAsHtml $scope.ui.article.data.content
        
        $scope.ui.content_html = $sce.trustAsHtml $scope.ui.article.data.content if $rows.find('video').toArray().length == 0

        _.each $rows.find('video').toArray(), (data, i) ->
          $http.get "#{config.api.baseUrl}/cumpus/programa/list/video",
            params:
              url: $(data).data('src')
          .then (res) ->
            _oldHtml3 = _.unescape $('<p>').append($(data).clone()).html()
            _src = $(data).data('src')
            $(data).removeAttr('data-src')
            _oldHtml = _.unescape $('<p>').append($(data).clone()).html()
            if data.src
              _oldHtml2 = _oldHtml.replace(data.src, "#{config.api.baseUrl}#{res.data.url}")
            _newHtml = _.unescape $('<p>').append($(data).clone()).html()
            $scope.ui.article.data.content = $scope.ui.article.data.content.replace(_oldHtml3, "<p style='text-align: center;'>#{_oldHtml2}</p>").replace(data.src, "#{config.api.baseUrl}#{res.data.url}") if data.src
            $scope.ui.content_html = $sce.trustAsHtml $scope.ui.article.data.content
    .catch (error) ->
