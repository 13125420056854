angular.module 'app'
.controller 'ImagesCtrl', ($timeout, $scope, $state, $stateParams, activeUser, $http, config) ->
  $scope.ui =
    baseUrl: config.api.baseUrl
    columnName: $stateParams.columnName
    currentPage: 1
    perPage: 12
    maxSize: 8
    totalItems: 0
    datas: []
    opts:
      index: 0
    startEvent: 'START_GALLERY'

  $scope.showGallery = (i) ->
    $scope.ui.opts.index = i || $scope.ui.opts.index
    $scope.$broadcast($scope.ui.startEvent)

  findDatas = ->
    $http.get "#{config.api.baseUrl}/cumpus/programa/list",
      params:
        is_show: true
        programa_id: $stateParams.columnId
        group_id: config.user.group_id
        sort: '-created_at'
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
    .then (res) ->
      $scope.ui.totalItems = res.data.count
      $scope.ui.datas = res.data.data
      _.each $scope.ui.datas, (val, index) ->
        val.created_at = moment(val.created_at).format 'YYYY-MM-DD'
        val.thumb = "#{$scope.ui.baseUrl}#{val.data.url}"
        val.description = val.data.description
        val.img = "#{$scope.ui.baseUrl}#{val.data.url}"
    #.then ->
    #  $timeout (->
    #    $('#masonry-box').masonry({itemSelector: '.item'})
    #  ), 200

    .catch (error) ->

  $scope.$watch 'ui.currentPage', findDatas
