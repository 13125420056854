angular.module 'app'
.controller 'SearchCtrl', ($sce, $window, $scope, $state, $stateParams, activeUser, $http, config) ->
  $scope.ui =
    baseUrl: config.api.baseUrl
    currentPage: 1
    perPage: 10
    maxSize: 8
    totalItems: 0
    datas: []

  findDatas = ->
    $http.get "#{config.api.baseUrl}/cumpus/programa/list",
      params:
        is_show: true
        group_id: config.user.group_id
        sort: '-created_at'
        q: $stateParams.q
        end_release_time: String moment().add('d', 1)
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
    .then (res) ->
      $scope.ui.totalItems = res.data.count
      $scope.ui.datas = res.data.data
      _.each $scope.ui.datas, (val) ->
        val.created_at = moment(val.created_at).format 'YYYY-MM-DD'
        if val.is_head is true
          val.title = val.title.replace(new RegExp("("+$stateParams.q+")", "gi"), "<span class='ers-keyword'>$1</span>")
          val.title = $sce.trustAsHtml val.title
        else
          val.data.title = val.data.title.replace(new RegExp("("+$stateParams.q+")", "gi"), "<span class='ers-keyword'>$1</span>")
          val.data.title = $sce.trustAsHtml val.data.title

    .catch (error) ->

  $scope.$watch 'ui.currentPage', findDatas

  $scope.toNew = (article) ->
    if article.is_head is false
      if article.type is 'link'
         $window.open(article.data.link)
      else
        $state.go('app.article', {columnId: article.programa_id, articleId: article._id, page: 1})
    else
      if article.type is 'link'
         $window.open(article.link)
      else
        $state.go('app.new', {newId: article._id})
