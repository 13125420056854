angular.module 'app'
.controller 'AdCtrl', ($sce, $scope, $state, $stateParams, activeUser, $http, config) ->
  $scope.ui =
    ad: {}


  do findAd = ->
    $http.get "#{config.api.baseUrl}/cumpus/ad/#{$stateParams.adId}",
      params:
        group_id: config.user.group_id
    .then (res) ->
      $scope.ui.ad = res.data
      $scope.ui.content_html = $sce.trustAsHtml $scope.ui.ad.content
    .catch (error) ->
