angular.module 'app'
.controller 'WelcomeCtrl', ($scope, $state, activeUser, $http, config) ->
  $scope.ui =
    baseUrl: config.api.baseUrl
    homePage: activeUser.homepage
    slides: []
    centerColumns: []
    bottomColumns: []
    headMenus: []
    interval_1: 5000
    interval_2: 3000

  do findSlides = ->
    $http.get "#{config.api.baseUrl}/cumpus/programa/list",
      params:
        is_head: true
        limit: 10
        offset: 0
        group_id: config.user.group_id
        sort: '-created_at'
    .then (res) ->
      $scope.ui.slides = res.data.data
    .catch (error) ->

  do findColumns = ->
    if $scope.ui.homePage.layouts?.length > 0
      _.each $scope.ui.homePage.layouts, (layout) ->
        if layout.is_useing is true
          $scope.ui.centerColumns = layout.centerColumns
          $scope.ui.headMenus = layout.headMenus


  $scope.toColumn = (column) ->
    # 文章列表(1) 單文章/專題(2) 相冊(3) 外部鏈接(4)

    if column.type is '1'
      $state.go('app.articles', {columnId: column._id, columnName: column.programa_name})
    else if column.type is '2'
      $state.go('app.single-article', {columnId: column._id})
    else if column.type is '3'
      $state.go('app.images', {columnId: column._id, columnName: column.programa_name})
    else if column.type is '4'
      window.open(column.data.link)
    else if column.type is '5'
      $state.go('app.text-articles', {columnId: column._id, columnName: column.programa_name})
    else if column.type is '6'
      $state.go('app.links', {columnId: column._id, columnName: column.programa_name})

  $scope.toArticle = (article, index) ->
    $state.go('app.article', {columnId: article.programa_id, articleId: article._id, index: index})

