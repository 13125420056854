var app;

app = angular.module('app', ['ngAnimate', 'ngCookies', 'ui.bootstrap', 'ui.router', 'jkuri.gallery']);

app.config(function($stateProvider, $urlRouterProvider) {
  $stateProvider.state('app', {
    url: '',
    abstract: true,
    templateUrl: 'apps/home/home',
    controller: 'HomeCtrl',
    resolve: {
      activeProject: function($http, $state, activeUser, config) {
        return $http.get(config.api.baseUrl + "/cumpus/homepage", {
          params: {
            group_id: config.user.group_id
          }
        }).then(function(res) {
          if (res.data.data.length > 0) {
            return activeUser.homepage = res.data.data[0];
          }
        })["catch"](function(error) {});
      }
    }
  }).state('app.welcome', {
    url: '/welcome',
    cache: true,
    templateUrl: 'apps/welcome/welcome',
    controller: 'WelcomeCtrl'
  }).state('app.links', {
    url: '/column/:columnId/links/:columnName',
    cache: true,
    templateUrl: 'apps/links/links',
    controller: 'LinksCtrl'
  }).state('app.articles', {
    url: '/column/:columnId/articles/:columnName',
    cache: true,
    templateUrl: 'apps/articles/articles',
    controller: 'ArticlesCtrl'
  }).state('app.article', {
    url: '/column/:columnId/article/:articleId/:page',
    cache: true,
    templateUrl: 'apps/article/article',
    controller: 'ArticleCtrl'
  }).state('app.text-articles', {
    url: '/column/:columnId/text-articles/:columnName',
    cache: true,
    templateUrl: 'apps/text-articles/text-articles',
    controller: 'TextArticlesCtrl'
  }).state('app.text-article', {
    url: '/column/:columnId/text-article/:articleId/:page',
    cache: true,
    templateUrl: 'apps/text-article/text-article',
    controller: 'TextArticleCtrl'
  }).state('app.new', {
    url: '/new/:newId',
    cache: true,
    templateUrl: 'apps/new/new',
    controller: 'NewCtrl'
  }).state('app.ad', {
    url: '/ad/:adId',
    cache: true,
    templateUrl: 'apps/ad/ad',
    controller: 'AdCtrl'
  }).state('app.single-article', {
    url: '/single-article/:columnId',
    cache: true,
    templateUrl: 'apps/single-article/single-article',
    controller: 'SingleArticleCtrl'
  }).state('app.images', {
    url: '/column/:columnId/images/:columnName',
    cache: true,
    templateUrl: 'apps/images/images',
    controller: 'ImagesCtrl'
  }).state('app.search', {
    url: '/search/:q',
    cache: true,
    templateUrl: 'apps/search/search',
    controller: 'SearchCtrl'
  });
  return $urlRouterProvider.otherwise('/welcome');
});

angular.module('app').controller('AdCtrl', function($sce, $scope, $state, $stateParams, activeUser, $http, config) {
  var findAd;
  $scope.ui = {
    ad: {}
  };
  return (findAd = function() {
    return $http.get(config.api.baseUrl + "/cumpus/ad/" + $stateParams.adId, {
      params: {
        group_id: config.user.group_id
      }
    }).then(function(res) {
      $scope.ui.ad = res.data;
      return $scope.ui.content_html = $sce.trustAsHtml($scope.ui.ad.content);
    })["catch"](function(error) {});
  })();
});

angular.module('app').controller('AdsCtrl', function($window, $scope, $state, activeUser, $http, config) {
  var findData;
  $scope.ui = {
    baseUrl: config.api.baseUrl,
    ads: []
  };
  (findData = function() {
    return $http.get(config.api.baseUrl + "/cumpus/ad", {
      params: {
        limit: 4,
        offset: 0,
        group_id: config.user.group_id,
        sort: 'sort',
        is_show: true
      }
    }).then(function(res) {
      return $scope.ui.ads = res.data.data;
    })["catch"](function(error) {});
  })();
  return $scope.toAd = function(ad) {
    if (ad.type === 'link') {
      return $window.open(ad.link);
    } else {
      return $state.go('app.ad', {
        adId: ad._id
      });
    }
  };
});

angular.module('app').controller('ArticleCtrl', function($sce, $scope, $state, $stateParams, activeUser, $http, config) {
  var findArticle, findColumn, findDatas;
  $scope.ui = {
    columnId: $stateParams.columnId,
    article: {},
    totalItems: 0,
    datas: [],
    previous: {},
    next: {},
    isPrevious: false,
    isNext: false
  };
  (findColumn = function() {
    return $http.get(config.api.baseUrl + "/cumpus/programa/" + $stateParams.columnId, {}).then(function(res) {
      return $scope.ui.column = res.data;
    })["catch"](function(error) {});
  })();
  (findArticle = function() {
    return $http.get(config.api.baseUrl + "/cumpus/programa/list/" + $stateParams.articleId, {
      params: {
        is_content: true,
        programa_id: $stateParams.columnId,
        group_id: config.user.group_id
      }
    }).then(function(res) {
      var $rows, _content;
      $scope.ui.article = res.data;
      $scope.ui.article.release_time = moment($scope.ui.article.release_time).format('YYYY-MM-DD');
      $scope.ui.article.data.content = $scope.ui.article.data.content.replace(/\"http\:\/\/mmbiz.qpic.cn/g, '"' + config.api.baseUrl + '/imgserve?_url=http://mmbiz.qpic.cn').replace(/\(http\:\/\/mmbiz.qpic.cn/g, '(' + config.api.baseUrl + '/imgserve?_url=http://mmbiz.qpic.cn').replace(/\&quot;http\:\/\/mmbiz.qpic.cn/g, '&quot;' + config.api.baseUrl + '/imgserve?_url=http://mmbiz.qpic.cn').replace(/\"https\:\/\/mmbiz.qpic.cn/g, '"' + config.api.baseUrl + '/imgserve?_url=https://mmbiz.qpic.cn').replace(/\(https\:\/\/mmbiz.qpic.cn/g, '(' + config.api.baseUrl + '/imgserve?_url=https://mmbiz.qpic.cn').replace(/\&quot;https\:\/\/mmbiz.qpic.cn/g, '&quot;' + config.api.baseUrl + '/imgserve?_url=https://mmbiz.qpic.cn');
      _content = _.clone($scope.ui.article.data.content);
      _content = $("<div>" + _content + "</div>").html();
      $rows = $("<p>" + _content + "</p>");
      if ($rows.find('iframe').toArray().length === 0) {
        $scope.ui.content_html = $sce.trustAsHtml($scope.ui.article.data.content);
      }
      return _.each($rows.find('iframe').toArray(), function(data, i) {
        return $http.get(config.api.baseUrl + "/cumpus/programa/list/video", {
          params: {
            url: $(data).data('src')
          }
        }).then(function(res) {
          var _newHtml, _oldHtml, _oldHtml2, _oldHtml3, _src;
          _oldHtml3 = _.unescape($('<p>').append($(data).clone()).html());
          _src = $(data).data('src');
          $(data).removeAttr('data-src');
          _oldHtml = _.unescape($('<p>').append($(data).clone()).html());
          if (data.src) {
            _oldHtml2 = _oldHtml.replace(data.src, "" + config.api.baseUrl + res.data.url);
          }
          _newHtml = _.unescape($('<p>').append($(data).clone()).html());
          if (data.src) {
            $scope.ui.article.data.content = $scope.ui.article.data.content.replace(_oldHtml3, "<p style='text-align: center;'>" + _oldHtml2 + "</p>");
          }
          return $scope.ui.content_html = $sce.trustAsHtml($scope.ui.article.data.content);
        });
      });
    })["catch"](function(error) {});
  })();
  (findDatas = function() {
    return $http.get(config.api.baseUrl + "/cumpus/programa/list", {
      params: {
        is_show: true,
        programa_id: $stateParams.columnId,
        group_id: config.user.group_id,
        sort: '-release_time',
        end_release_time: String(moment().add('d', 1)),
        limit: 10,
        offset: (Number($stateParams.page) - 1) * 10
      }
    }).then(function(res) {
      $scope.ui.totalItems = res.data.count;
      $scope.ui.datas = res.data.data;
      return _.each($scope.ui.datas, function(val) {
        return val.release_time = moment(val.release_time).format('YYYY/MM/DD');
      });
    });
  })();
  return $scope.toArticle = function(article) {
    return $state.go('app.article', {
      columnId: article.programa_id,
      articleId: article._id,
      page: 1
    });
  };
});

angular.module('app').controller('ArticlesCtrl', function($scope, $state, $stateParams, activeUser, $http, config) {
  var findDatas;
  $scope.ui = {
    baseUrl: config.api.baseUrl,
    columnName: $stateParams.columnName,
    currentPage: 1,
    perPage: 10,
    maxSize: 8,
    totalItems: 0,
    datas: []
  };
  findDatas = function() {
    return $http.get(config.api.baseUrl + "/cumpus/programa/list", {
      params: {
        is_show: true,
        programa_id: $stateParams.columnId,
        group_id: config.user.group_id,
        sort: '-release_time',
        end_release_time: String(moment().add('d', 1)),
        limit: $scope.ui.perPage,
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
      }
    }).then(function(res) {
      $scope.ui.totalItems = res.data.count;
      $scope.ui.datas = res.data.data;
      return _.each($scope.ui.datas, function(val) {
        return val.release_time = moment(val.release_time).format('YYYY-MM-DD');
      });
    })["catch"](function(error) {});
  };
  $scope.$watch('ui.currentPage', findDatas);
  return $scope.toArticle = function(article) {
    return $state.go('app.article', {
      columnId: article.programa_id,
      articleId: article._id,
      page: $scope.ui.currentPage
    });
  };
});

angular.module('app').controller('ColumnLayoutCtrl', function($window, $scope, $state, activeUser, $http, config) {
  var findArticles, findBottomColumnsData, findColumns;
  $scope.ui = {
    baseUrl: config.api.baseUrl,
    homePage: activeUser.homepage,
    bottomColumns: [],
    myInterval: 5000
  };
  findArticles = function(column, index) {
    var option;
    option = {
      is_show: true,
      programa_id: column._id,
      group_id: config.user.group_id,
      sort: '-release_time',
      offset: 0,
      limit: 6
    };
    if (column.type !== '3') {
      option.end_release_time = String(moment().add('d', 1));
    }
    return $http.get(config.api.baseUrl + "/cumpus/programa/list", {
      params: option
    }).then(function(res) {
      var articles;
      articles = res.data.data;
      _.each(articles, function(val) {
        return val.release_time = moment(val.release_time).format('YYYY/MM/DD');
      });
      column.articles = articles;
      if (index === 2) {
        return $scope.ui.slides = articles;
      }
    })["catch"](function(error) {});
  };
  findBottomColumnsData = function() {
    return _.each($scope.ui.bottomColumns, function(column, index) {
      return findArticles(column, index);
    });
  };
  (findColumns = function() {
    var ref;
    if (((ref = $scope.ui.homePage.layouts) != null ? ref.length : void 0) > 0) {
      return _.each($scope.ui.homePage.layouts, function(layout) {
        if (layout.is_useing === true) {
          $scope.ui.centerColumns = layout.centerColumns;
          $scope.ui.bottomColumns = layout.bottomColumns;
          return findBottomColumnsData();
        }
      });
    }
  })();
  $scope.toColumn = function(column) {
    if (column.type === '1') {
      return $state.go('app.articles', {
        columnId: column._id,
        columnName: column.programa_name
      });
    } else if (column.type === '2') {
      return $state.go('app.single-article', {
        columnId: column._id
      });
    } else if (column.type === '3') {
      return $state.go('app.images', {
        columnId: column._id,
        columnName: column.programa_name
      });
    } else if (column.type === '4') {
      $window.open(column.data.link, "_self");
      return true;
    } else if (column.type === '5') {
      return $state.go('app.text-articles', {
        columnId: column._id,
        columnName: column.programa_name
      });
    } else if (column.type === '6') {
      return $state.go('app.links', {
        columnId: column._id,
        columnName: column.programa_name
      });
    }
  };
  return $scope.toArticle = function(article, index) {
    if (article.type === 'link') {
      return $window.open(article.data.link);
    } else {
      return $state.go('app.article', {
        columnId: article.programa_id,
        articleId: article._id,
        page: 1
      });
    }
  };
});

angular.module('app').controller('HeaderCtrl', function($window, $scope, $state, activeUser, $http, config) {
  var findColumns, findDatas, ref;
  $scope.ui = {
    homePage: activeUser.homepage,
    dynamicPopover: {
      qrcodeImg: '',
      templateUrl: 'qrcodePopoverTemplate.html'
    },
    headMenus: [],
    centerColumns: [],
    q: '',
    query: '',
    datas: [],
    isShow: false
  };
  if (((ref = $scope.ui.homePage.qr_code) != null ? ref.length : void 0) > 0) {
    $scope.ui.dynamicPopover.qrcodeImg = "" + config.api.baseUrl + $scope.ui.homePage.qr_code;
  }
  (findColumns = function() {
    var ref1;
    if (((ref1 = $scope.ui.homePage.layouts) != null ? ref1.length : void 0) > 0) {
      return _.each($scope.ui.homePage.layouts, function(layout) {
        if (layout.is_useing === true) {
          $scope.ui.headMenus = layout.headMenus;
          return $scope.ui.centerColumns = layout.centerColumns;
        }
      });
    }
  })();
  $scope.toColumn = function(column) {
    if (column.type === '1') {
      return $state.go('app.articles', {
        columnId: column._id,
        columnName: column.programa_name
      });
    } else if (column.type === '2') {
      return $state.go('app.single-article', {
        columnId: column._id
      });
    } else if (column.type === '3') {
      return $state.go('app.images', {
        columnId: column._id,
        columnName: column.programa_name
      });
    } else if (column.type === '4') {
      return $window.open(column.data.link);
    } else if (column.type === '5') {
      return $state.go('app.text-articles', {
        columnId: column._id,
        columnName: column.programa_name
      });
    } else if (column.type === '6') {
      return $state.go('app.links', {
        columnId: column._id,
        columnName: column.programa_name
      });
    }
  };
  $scope.toNew = function(article) {
    $scope.ui.isShow = false;
    if (article.is_head === false) {
      if (article.type === 'link') {
        return $window.open(article.data.link);
      } else {
        return $state.go('app.article', {
          columnId: article.programa_id,
          articleId: article._id,
          page: 1
        });
      }
    } else {
      if (article.type === 'link') {
        return $window.open(article.link);
      } else {
        return $state.go('app.new', {
          newId: article._id
        });
      }
    }
  };
  findDatas = function() {
    if ($scope.ui.q.length === 0) {
      $scope.ui.datas = [];
      $scope.ui.isShow = false;
      return;
    }
    return $http.get(config.api.baseUrl + "/cumpus/programa/list", {
      params: {
        is_show: true,
        group_id: config.user.group_id,
        sort: '-release_time',
        q: $scope.ui.q,
        limit: 8,
        offset: 0,
        end_release_time: String(moment().add('d', 1))
      }
    }).then(function(res) {
      $scope.ui.datas = res.data.data;
      if ($scope.ui.datas.length > 0) {
        return $scope.ui.isShow = true;
      }
    })["catch"](function(error) {});
  };
  $scope.findDatas = findDatas;
  $scope.$watch('ui.q', findDatas);
  $scope.search = function() {
    if ($scope.ui.q.length !== 0) {
      return $state.go('app.search', {
        q: $scope.ui.q
      });
    }
  };
  return $scope.toOpen = function() {
    if ($scope.ui.datas.length > 0) {
      return $scope.ui.isShow = true;
    }
  };
});

angular.module('app').controller('HomeAdsCtrl', function($window, $scope, $state, activeUser, $http, config) {
  var findData;
  $scope.ui = {
    baseUrl: config.api.baseUrl,
    ads: []
  };
  (findData = function() {
    return $http.get(config.api.baseUrl + "/cumpus/ad", {
      params: {
        limit: 4,
        offset: 0,
        group_id: config.user.group_id,
        sort: 'sort',
        is_show: true
      }
    }).then(function(res) {
      return $scope.ui.ads = res.data.data;
    })["catch"](function(error) {});
  })();
  return $scope.toAd = function(ad) {
    if (ad.type === 'link') {
      return $window.open(ad.link);
    } else {
      return $state.go('app.ad', {
        adId: ad._id
      });
    }
  };
});

angular.module('app').controller('HomeCtrl', function($rootScope, $location, $scope, $state, activeUser, $http, config) {
  var ref;
  $scope.ui = {
    school: 1,
    path: $location.path(),
    homePage: activeUser.homepage,
    dynamicPopover: {
      qrcodeImg: '',
      templateUrl: 'qrcodePopoverTemplate.html'
    },
    copyright: activeUser.homepage.copyright || "COPYRIGHT(C)2015 KaoYip Middle School."
  };
  if (((ref = $scope.ui.homePage.qr_code) != null ? ref.length : void 0) > 0) {
    return $scope.ui.dynamicPopover.qrcodeImg = "" + config.api.baseUrl + $scope.ui.homePage.qr_code;
  }
});

angular.module('app').controller('ImagesCtrl', function($timeout, $scope, $state, $stateParams, activeUser, $http, config) {
  var findDatas;
  $scope.ui = {
    baseUrl: config.api.baseUrl,
    columnName: $stateParams.columnName,
    currentPage: 1,
    perPage: 12,
    maxSize: 8,
    totalItems: 0,
    datas: [],
    opts: {
      index: 0
    },
    startEvent: 'START_GALLERY'
  };
  $scope.showGallery = function(i) {
    $scope.ui.opts.index = i || $scope.ui.opts.index;
    return $scope.$broadcast($scope.ui.startEvent);
  };
  findDatas = function() {
    return $http.get(config.api.baseUrl + "/cumpus/programa/list", {
      params: {
        is_show: true,
        programa_id: $stateParams.columnId,
        group_id: config.user.group_id,
        sort: '-created_at',
        limit: $scope.ui.perPage,
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
      }
    }).then(function(res) {
      $scope.ui.totalItems = res.data.count;
      $scope.ui.datas = res.data.data;
      return _.each($scope.ui.datas, function(val, index) {
        val.created_at = moment(val.created_at).format('YYYY-MM-DD');
        val.thumb = "" + $scope.ui.baseUrl + val.data.url;
        val.description = val.data.description;
        return val.img = "" + $scope.ui.baseUrl + val.data.url;
      });
    })["catch"](function(error) {});
  };
  return $scope.$watch('ui.currentPage', findDatas);
});

angular.module('app').controller('LinksCtrl', function($window, $scope, $state, $stateParams, activeUser, $http, config) {
  var findDatas;
  $scope.ui = {
    baseUrl: config.api.baseUrl,
    columnName: $stateParams.columnName,
    currentPage: 1,
    perPage: 10,
    maxSize: 8,
    totalItems: 0,
    datas: []
  };
  findDatas = function() {
    return $http.get(config.api.baseUrl + "/cumpus/programa/list", {
      params: {
        is_show: true,
        programa_id: $stateParams.columnId,
        group_id: config.user.group_id,
        sort: '-release_time',
        end_release_time: String(moment().add('d', 1)),
        limit: $scope.ui.perPage,
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
      }
    }).then(function(res) {
      $scope.ui.totalItems = res.data.count;
      $scope.ui.datas = res.data.data;
      return _.each($scope.ui.datas, function(val) {
        return val.release_time = moment(val.release_time).format('YYYY-MM-DD');
      });
    })["catch"](function(error) {});
  };
  $scope.$watch('ui.currentPage', findDatas);
  return $scope.toLink = function(article) {
    return $window.open(article.data.link);
  };
});

angular.module('app').controller('NewCtrl', function($window, $sce, $scope, $state, $stateParams, activeUser, $http, config) {
  var findArticle, findData;
  $scope.ui = {
    article: {},
    datas: []
  };
  (findArticle = function() {
    return $http.get(config.api.baseUrl + "/cumpus/headline/" + $stateParams.newId, {
      params: {
        is_content: true,
        group_id: config.user.group_id
      }
    }).then(function(res) {
      $scope.ui.article = res.data;
      $scope.ui.article.content = $scope.ui.article.content.replace(/\"http\:\/\/mmbiz.qpic.cn/g, '"' + config.api.baseUrl + '/imgserve?_url=http://mmbiz.qpic.cn').replace(/\(http\:\/\/mmbiz.qpic.cn/g, '(' + config.api.baseUrl + '/imgserve?_url=http://mmbiz.qpic.cn').replace(/\&quot;http\:\/\/mmbiz.qpic.cn/g, '&quot;' + config.api.baseUrl + '/imgserve?_url=http://mmbiz.qpic.cn').replace(/\"https\:\/\/mmbiz.qpic.cn/g, '"' + config.api.baseUrl + '/imgserve?_url=https://mmbiz.qpic.cn').replace(/\(https\:\/\/mmbiz.qpic.cn/g, '(' + config.api.baseUrl + '/imgserve?_url=https://mmbiz.qpic.cn').replace(/\&quot;https\:\/\/mmbiz.qpic.cn/g, '&quot;' + config.api.baseUrl + '/imgserve?_url=https://mmbiz.qpic.cn');
      return $scope.ui.content_html = $sce.trustAsHtml($scope.ui.article.content);
    })["catch"](function(error) {});
  })();
  (findData = function() {
    return $http.get(config.api.baseUrl + "/cumpus/headline", {
      params: {
        limit: 8,
        offset: 0,
        group_id: config.user.group_id,
        sort: 'sort',
        is_show: true
      }
    }).then(function(res) {
      $scope.ui.datas = res.data.data;
      return _.each($scope.ui.datas, function(val) {
        return val.created_at = moment(val.created_at).format('YYYY-MM-DD HH:MM:SS');
      });
    })["catch"](function(error) {});
  })();
  return $scope.toNew = function(article) {
    if (article.type === 'link') {
      return $window.open(article.link);
    } else {
      return $state.go('app.new', {
        newId: article._id
      });
    }
  };
});

angular.module('app').controller('RightNavbarCtrl', function($scope, $state, activeUser, $http, config) {
  var findColumns;
  $scope.ui = {
    homePage: activeUser.homepage,
    centerColumns: []
  };
  (findColumns = function() {
    var ref;
    if (((ref = $scope.ui.homePage.layouts) != null ? ref.length : void 0) > 0) {
      return _.each($scope.ui.homePage.layouts, function(layout) {
        if (layout.is_useing === true) {
          return $scope.ui.centerColumns = layout.centerColumns;
        }
      });
    }
  })();
  return $scope.toColumn = function(column) {
    if (column.type === '1') {
      return $state.go('app.articles', {
        columnId: column._id,
        columnName: column.programa_name
      });
    } else if (column.type === '2') {
      return $state.go('app.single-article', {
        columnId: column._id
      });
    } else if (column.type === '3') {
      return $state.go('app.images', {
        columnId: column._id,
        columnName: column.programa_name
      });
    } else if (column.type === '4') {
      return window.open(column.data.link);
    } else if (column.type === '5') {
      return $state.go('app.text-articles', {
        columnId: column._id,
        columnName: column.programa_name
      });
    } else if (column.type === '6') {
      return $state.go('app.links', {
        columnId: column._id,
        columnName: column.programa_name
      });
    }
  };
});

angular.module('app').controller('SearchCtrl', function($sce, $window, $scope, $state, $stateParams, activeUser, $http, config) {
  var findDatas;
  $scope.ui = {
    baseUrl: config.api.baseUrl,
    currentPage: 1,
    perPage: 10,
    maxSize: 8,
    totalItems: 0,
    datas: []
  };
  findDatas = function() {
    return $http.get(config.api.baseUrl + "/cumpus/programa/list", {
      params: {
        is_show: true,
        group_id: config.user.group_id,
        sort: '-created_at',
        q: $stateParams.q,
        end_release_time: String(moment().add('d', 1)),
        limit: $scope.ui.perPage,
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
      }
    }).then(function(res) {
      $scope.ui.totalItems = res.data.count;
      $scope.ui.datas = res.data.data;
      return _.each($scope.ui.datas, function(val) {
        val.created_at = moment(val.created_at).format('YYYY-MM-DD');
        if (val.is_head === true) {
          val.title = val.title.replace(new RegExp("(" + $stateParams.q + ")", "gi"), "<span class='ers-keyword'>$1</span>");
          return val.title = $sce.trustAsHtml(val.title);
        } else {
          val.data.title = val.data.title.replace(new RegExp("(" + $stateParams.q + ")", "gi"), "<span class='ers-keyword'>$1</span>");
          return val.data.title = $sce.trustAsHtml(val.data.title);
        }
      });
    })["catch"](function(error) {});
  };
  $scope.$watch('ui.currentPage', findDatas);
  return $scope.toNew = function(article) {
    if (article.is_head === false) {
      if (article.type === 'link') {
        return $window.open(article.data.link);
      } else {
        return $state.go('app.article', {
          columnId: article.programa_id,
          articleId: article._id,
          page: 1
        });
      }
    } else {
      if (article.type === 'link') {
        return $window.open(article.link);
      } else {
        return $state.go('app.new', {
          newId: article._id
        });
      }
    }
  };
});

angular.module('app').controller('SingleArticleCtrl', function($scope, $http, $state, $sce, $stateParams, config, activeUser) {
  var findArticle, findColumn;
  $scope.ui = {
    column: {},
    article: {},
    content_html: null,
    show: true
  };
  (findColumn = function() {
    return $http.get(config.api.baseUrl + "/cumpus/programa/" + $stateParams.columnId, {}).then(function(res) {
      $scope.ui.column = res.data;
      if (res.data.type && res.data.type === "2") {
        return $scope.ui.show = false;
      }
    })["catch"](function(error) {});
  })();
  return (findArticle = function() {
    return $http.get(config.api.baseUrl + "/cumpus/programa/list", {
      params: {
        is_content: true,
        programa_id: $stateParams.columnId,
        group_id: config.user.group_id
      }
    }).then(function(res) {
      var $rows, _content;
      if (res.data.data.length > 0) {
        $scope.ui.article = res.data.data[0];
        if ($scope.ui.article.release_time !== null) {
          $scope.ui.article.release_time = moment($scope.ui.article.release_time).format('YYYY-MM-DD');
        }
        _content = _.clone($scope.ui.article.data.content);
        _content = $("<div>" + _content + "</div>").html();
        $rows = $("<p>" + _content + "</p>");
        if ($rows.find('iframe').toArray().length === 0) {
          $scope.ui.content_html = $sce.trustAsHtml($scope.ui.article.data.content);
        }
        _.each($rows.find('iframe').toArray(), function(data, i) {
          return $http.get(config.api.baseUrl + "/cumpus/programa/list/video", {
            params: {
              url: $(data).data('src')
            }
          }).then(function(res) {
            var _newHtml, _oldHtml, _oldHtml2, _oldHtml3, _src;
            _oldHtml3 = _.unescape($('<p>').append($(data).clone()).html());
            _src = $(data).data('src');
            $(data).removeAttr('data-src');
            _oldHtml = _.unescape($('<p>').append($(data).clone()).html());
            if (data.src) {
              _oldHtml2 = _oldHtml.replace(data.src, "" + config.api.baseUrl + res.data.url);
            }
            _newHtml = _.unescape($('<p>').append($(data).clone()).html());
            if (data.src) {
              $scope.ui.article.data.content = $scope.ui.article.data.content.replace(_oldHtml3, "<p style='text-align: center;'>" + _oldHtml2 + "</p>").replace(data.src, "" + config.api.baseUrl + res.data.url);
            }
            return $scope.ui.content_html = $sce.trustAsHtml($scope.ui.article.data.content);
          });
        });
        if ($rows.find('video').toArray().length === 0) {
          $scope.ui.content_html = $sce.trustAsHtml($scope.ui.article.data.content);
        }
        return _.each($rows.find('video').toArray(), function(data, i) {
          return $http.get(config.api.baseUrl + "/cumpus/programa/list/video", {
            params: {
              url: $(data).data('src')
            }
          }).then(function(res) {
            var _newHtml, _oldHtml, _oldHtml2, _oldHtml3, _src;
            _oldHtml3 = _.unescape($('<p>').append($(data).clone()).html());
            _src = $(data).data('src');
            $(data).removeAttr('data-src');
            _oldHtml = _.unescape($('<p>').append($(data).clone()).html());
            if (data.src) {
              _oldHtml2 = _oldHtml.replace(data.src, "" + config.api.baseUrl + res.data.url);
            }
            _newHtml = _.unescape($('<p>').append($(data).clone()).html());
            if (data.src) {
              $scope.ui.article.data.content = $scope.ui.article.data.content.replace(_oldHtml3, "<p style='text-align: center;'>" + _oldHtml2 + "</p>").replace(data.src, "" + config.api.baseUrl + res.data.url);
            }
            return $scope.ui.content_html = $sce.trustAsHtml($scope.ui.article.data.content);
          });
        });
      }
    })["catch"](function(error) {});
  })();
});

angular.module('app').controller('TextArticleCtrl', function($sce, $scope, $state, $stateParams, activeUser, $http, config) {
  var findArticle, findColumn, findDatas;
  $scope.ui = {
    article: {},
    totalItems: 0,
    datas: [],
    previous: {},
    next: {},
    isPrevious: false,
    isNext: false
  };
  (findColumn = function() {
    return $http.get(config.api.baseUrl + "/cumpus/programa/" + $stateParams.columnId, {}).then(function(res) {
      return $scope.ui.column = res.data;
    })["catch"](function(error) {});
  })();
  (findArticle = function() {
    return $http.get(config.api.baseUrl + "/cumpus/programa/list/" + $stateParams.articleId, {
      params: {
        is_content: true,
        programa_id: $stateParams.columnId,
        group_id: config.user.group_id
      }
    }).then(function(res) {
      $scope.ui.article = res.data;
      $scope.ui.article.release_time = moment($scope.ui.article.release_time).format('YYYY-MM-DD');
      return $scope.ui.content_html = $sce.trustAsHtml($scope.ui.article.data.content);
    })["catch"](function(error) {});
  })();
  (findDatas = function() {
    return $http.get(config.api.baseUrl + "/cumpus/programa/list", {
      params: {
        is_show: true,
        programa_id: $stateParams.columnId,
        group_id: config.user.group_id,
        sort: '-release_time',
        end_release_time: String(moment().add('d', 1)),
        limit: 10,
        offset: (Number($stateParams.page) - 1) * 10
      }
    }).then(function(res) {
      $scope.ui.totalItems = res.data.count;
      $scope.ui.datas = res.data.data;
      return _.each($scope.ui.datas, function(val) {
        return val.release_time = moment(val.release_time).format('YYYY/MM/DD');
      });
    });
  })();
  return $scope.toArticle = function(article) {
    return $state.go('app.article', {
      columnId: article.programa_id,
      articleId: article._id,
      page: 1
    });
  };
});

angular.module('app').controller('TextArticlesCtrl', function($scope, $state, $stateParams, activeUser, $http, config) {
  var findDatas;
  $scope.ui = {
    baseUrl: config.api.baseUrl,
    columnName: $stateParams.columnName,
    currentPage: 1,
    perPage: 10,
    maxSize: 8,
    totalItems: 0,
    datas: []
  };
  findDatas = function() {
    return $http.get(config.api.baseUrl + "/cumpus/programa/list", {
      params: {
        is_show: true,
        programa_id: $stateParams.columnId,
        group_id: config.user.group_id,
        sort: '-release_time',
        end_release_time: String(moment().add('d', 1)),
        limit: $scope.ui.perPage,
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
      }
    }).then(function(res) {
      $scope.ui.totalItems = res.data.count;
      $scope.ui.datas = res.data.data;
      return _.each($scope.ui.datas, function(val) {
        return val.release_time = moment(val.release_time).format('YYYY-MM-DD');
      });
    })["catch"](function(error) {});
  };
  $scope.$watch('ui.currentPage', findDatas);
  return $scope.toArticle = function(article) {
    return $state.go('app.article', {
      columnId: article.programa_id,
      articleId: article._id,
      page: $scope.ui.currentPage
    });
  };
});

angular.module('app').controller('TopNewsCtrl', function($window, $scope, $state, activeUser, $http, config) {
  var findData;
  $scope.ui = {
    baseUrl: config.api.baseUrl,
    slides: [],
    interval: 4000
  };
  (findData = function() {
    return $http.get(config.api.baseUrl + "/cumpus/headline", {
      params: {
        limit: 8,
        offset: 0,
        group_id: config.user.group_id,
        sort: 'sort',
        is_show: true
      }
    }).then(function(res) {
      $scope.ui.slides = res.data.data;
      return _.each($scope.ui.slides, function(val) {
        return val.created_at = moment(val.created_at).format('YYYY-MM-DD HH:MM:SS');
      });
    })["catch"](function(error) {});
  })();
  $scope.toNew = function(article) {
    if (article.type === 'link') {
      return $window.open(article.link);
    } else {
      return $state.go('app.new', {
        newId: article._id
      });
    }
  };
  return $scope.selectImg = function(slide) {
    slide.active = true;
    return _.each($scope.ui.slides, function(val) {
      if (val._id !== slide._id) {
        return val.active = false;
      }
    });
  };
});

angular.module('app').controller('WelcomeCtrl', function($scope, $state, activeUser, $http, config) {
  var findColumns, findSlides;
  $scope.ui = {
    baseUrl: config.api.baseUrl,
    homePage: activeUser.homepage,
    slides: [],
    centerColumns: [],
    bottomColumns: [],
    headMenus: [],
    interval_1: 5000,
    interval_2: 3000
  };
  (findSlides = function() {
    return $http.get(config.api.baseUrl + "/cumpus/programa/list", {
      params: {
        is_head: true,
        limit: 10,
        offset: 0,
        group_id: config.user.group_id,
        sort: '-created_at'
      }
    }).then(function(res) {
      return $scope.ui.slides = res.data.data;
    })["catch"](function(error) {});
  })();
  (findColumns = function() {
    var ref;
    if (((ref = $scope.ui.homePage.layouts) != null ? ref.length : void 0) > 0) {
      return _.each($scope.ui.homePage.layouts, function(layout) {
        if (layout.is_useing === true) {
          $scope.ui.centerColumns = layout.centerColumns;
          return $scope.ui.headMenus = layout.headMenus;
        }
      });
    }
  })();
  $scope.toColumn = function(column) {
    if (column.type === '1') {
      return $state.go('app.articles', {
        columnId: column._id,
        columnName: column.programa_name
      });
    } else if (column.type === '2') {
      return $state.go('app.single-article', {
        columnId: column._id
      });
    } else if (column.type === '3') {
      return $state.go('app.images', {
        columnId: column._id,
        columnName: column.programa_name
      });
    } else if (column.type === '4') {
      return window.open(column.data.link);
    } else if (column.type === '5') {
      return $state.go('app.text-articles', {
        columnId: column._id,
        columnName: column.programa_name
      });
    } else if (column.type === '6') {
      return $state.go('app.links', {
        columnId: column._id,
        columnName: column.programa_name
      });
    }
  };
  return $scope.toArticle = function(article, index) {
    return $state.go('app.article', {
      columnId: article.programa_id,
      articleId: article._id,
      index: index
    });
  };
});

angular.module('app').constant('config', {
  api: {
    baseUrl: '/api'
  },
  webapp: {
    baseUrl: 'http://campus-app.ersinfotech.com/'
  },
  user: {
    group_id: 131
  }
});

angular.module("app").directive("ngEnter", function() {
  return function(scope, element, attrs) {
    return element.bind("keydown keypress", function(event) {
      if (event.which === 13) {
        scope.$apply(function() {
          return scope.$eval(attrs.ngEnter);
        });
        return event.preventDefault();
      }
    });
  };
});

angular.module('app').factory('activeUser', function() {
  return {};
});
