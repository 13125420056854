
app = angular.module 'app', [
  'ngAnimate'
  'ngCookies'
  'ui.bootstrap'
  'ui.router'
  'jkuri.gallery'
]

app.config ($stateProvider, $urlRouterProvider) ->


  $stateProvider

    .state 'app',
      url: ''
      abstract: true
      templateUrl: 'apps/home/home'
      controller: 'HomeCtrl'
      resolve:
        activeProject: ($http, $state, activeUser, config) ->
          $http.get "#{config.api.baseUrl}/cumpus/homepage",
            params:
              group_id: config.user.group_id
          .then (res) ->
            if res.data.data.length > 0
              activeUser.homepage = res.data.data[0]
          .catch (error) ->

    .state 'app.welcome',
      url: '/welcome'
      cache: true
      templateUrl: 'apps/welcome/welcome'
      controller: 'WelcomeCtrl'
      # resolve:
      #   activeProject: ($rootScope, $location) ->
      #     $rootScope.styleName = '/welcome'
      #     $rootScope.$emit 'testStyle'

    .state 'app.links',
      url: '/column/:columnId/links/:columnName'
      cache: true
      templateUrl: 'apps/links/links'
      controller: 'LinksCtrl'
      # resolve:
      #   activeProject: ($rootScope, $location) ->
      #     $rootScope.styleName = '/column/:columnId/links/:columnName'
      #     $rootScope.$emit 'testStyle'

    .state 'app.articles',
      url: '/column/:columnId/articles/:columnName'
      cache: true
      templateUrl: 'apps/articles/articles'
      controller: 'ArticlesCtrl'
      # resolve:
      #   activeProject: ($rootScope, $location) ->
      #     $rootScope.styleName = '/column/:columnId/articles/:columnName'
      #     $rootScope.$emit 'testStyle'

    .state 'app.article',
      url: '/column/:columnId/article/:articleId/:page'
      cache: true
      templateUrl: 'apps/article/article'
      controller: 'ArticleCtrl'
      # resolve:
      #   activeProject: ($rootScope, $location) ->
      #     $rootScope.styleName = '/column/:columnId/article/:articleId/:page'
      #     $rootScope.$emit 'testStyle'

    .state 'app.text-articles',
      url: '/column/:columnId/text-articles/:columnName'
      cache: true
      templateUrl: 'apps/text-articles/text-articles'
      controller: 'TextArticlesCtrl'
      # resolve:
      #   activeProject: ($rootScope, $location) ->
      #     $rootScope.styleName = 'apps/text-articles/text-articles'
      #     $rootScope.$emit 'testStyle'

    .state 'app.text-article',
      url: '/column/:columnId/text-article/:articleId/:page'
      cache: true
      templateUrl: 'apps/text-article/text-article'
      controller: 'TextArticleCtrl'
      # resolve:
      #   activeProject: ($rootScope, $location) ->
      #     $rootScope.styleName = '/column/:columnId/text-article/:articleId/:page'
      #     $rootScope.$emit 'testStyle'

    .state 'app.new',
      url: '/new/:newId'
      cache: true
      templateUrl: 'apps/new/new'
      controller: 'NewCtrl'
      # resolve:
      #   activeProject: ($rootScope, $location) ->
      #     $rootScope.styleName = '/new/:newId'
      #     $rootScope.$emit 'testStyle'

    .state 'app.ad',
      url: '/ad/:adId'
      cache: true
      templateUrl: 'apps/ad/ad'
      controller: 'AdCtrl'
      # resolve:
      #   activeProject: ($rootScope, $location) ->
      #     $rootScope.styleName = '/ad/:adId'
      #     $rootScope.$emit 'testStyle'

    .state 'app.single-article',
      url: '/single-article/:columnId'
      cache: true
      templateUrl: 'apps/single-article/single-article'
      controller: 'SingleArticleCtrl'
      # resolve:
      #   activeProject: ($rootScope, $location) ->
      #     $rootScope.styleName = '/single-article/:columnId'
      #     $rootScope.$emit 'testStyle'

    .state 'app.images',
      url: '/column/:columnId/images/:columnName'
      cache: true
      templateUrl: 'apps/images/images'
      controller: 'ImagesCtrl'
      # resolve:
      #   activeProject: ($rootScope, $location) ->
      #     $rootScope.styleName = '/column/:columnId/images/:columnName'
      #     $rootScope.$emit 'testStyle'

    .state 'app.search',
      url: '/search/:q'
      cache: true
      templateUrl: 'apps/search/search'
      controller: 'SearchCtrl'
      # resolve:
      #   activeProject: ($rootScope, $location) ->
      #     $rootScope.styleName = '/search/:q'
      #     $rootScope.$emit 'testStyle'

  $urlRouterProvider.otherwise '/welcome'



