angular.module 'app'
.controller 'HomeCtrl', ($rootScope, $location, $scope, $state, activeUser, $http, config) ->

  $scope.ui =
    school: 1
    path: $location.path()
    homePage: activeUser.homepage
    dynamicPopover:
      qrcodeImg: ''
      templateUrl: 'qrcodePopoverTemplate.html'
    copyright: activeUser.homepage.copyright or "COPYRIGHT(C)2015 KaoYip Middle School."

  if $scope.ui.homePage.qr_code?.length > 0
    $scope.ui.dynamicPopover.qrcodeImg = "#{config.api.baseUrl}#{$scope.ui.homePage.qr_code}"

  # $rootScope.$on 'testStyle', (event, option) ->
  #   $scope.ui.path = $rootScope.styleName
