angular.module 'app'
.controller 'NewCtrl', ($window, $sce, $scope, $state, $stateParams, activeUser, $http, config) ->
  $scope.ui =
    article: {}
    datas: []


  do findArticle = ->
    $http.get "#{config.api.baseUrl}/cumpus/headline/#{$stateParams.newId}",
      params:
        is_content: true
        group_id: config.user.group_id
    .then (res) ->
      $scope.ui.article = res.data
      $scope.ui.article.content = $scope.ui.article.content.replace(/\"http\:\/\/mmbiz.qpic.cn/g, '"' + config.api.baseUrl + '/imgserve?_url=http://mmbiz.qpic.cn').replace(/\(http\:\/\/mmbiz.qpic.cn/g, '(' + config.api.baseUrl + '/imgserve?_url=http://mmbiz.qpic.cn').replace(/\&quot;http\:\/\/mmbiz.qpic.cn/g, '&quot;' + config.api.baseUrl + '/imgserve?_url=http://mmbiz.qpic.cn').replace(/\"https\:\/\/mmbiz.qpic.cn/g, '"' + config.api.baseUrl + '/imgserve?_url=https://mmbiz.qpic.cn').replace(/\(https\:\/\/mmbiz.qpic.cn/g, '(' + config.api.baseUrl + '/imgserve?_url=https://mmbiz.qpic.cn').replace(/\&quot;https\:\/\/mmbiz.qpic.cn/g, '&quot;' + config.api.baseUrl + '/imgserve?_url=https://mmbiz.qpic.cn')
      $scope.ui.content_html = $sce.trustAsHtml $scope.ui.article.content
    .catch (error) ->

  do findData = ->
    $http.get "#{config.api.baseUrl}/cumpus/headline",
      params:
        limit: 8
        offset: 0
        group_id: config.user.group_id
        sort: 'sort'
        is_show: true
    .then (res) ->
      $scope.ui.datas = res.data.data
      _.each $scope.ui.datas, (val) ->
        val.created_at = moment(val.created_at).format 'YYYY-MM-DD HH:MM:SS'
    .catch (error) ->

  $scope.toNew = (article) ->
    if article.type is 'link'
       $window.open(article.link)
    else
      $state.go('app.new', {newId: article._id})
