angular.module 'app'
.controller 'ArticleCtrl', ($sce, $scope, $state, $stateParams, activeUser, $http, config) ->
  $scope.ui =
    columnId: $stateParams.columnId
    article: {}
    totalItems: 0
    datas: []
    previous: {}
    next: {}
    isPrevious: false
    isNext: false
  do findColumn = ->
    $http.get "#{config.api.baseUrl}/cumpus/programa/#{$stateParams.columnId}", {}
    .then (res) ->
      $scope.ui.column = res.data
    .catch (error) ->

  do findArticle = ->
    $http.get "#{config.api.baseUrl}/cumpus/programa/list/#{$stateParams.articleId}",
      params:
        is_content: true
        programa_id: $stateParams.columnId
        group_id: config.user.group_id
    .then (res) ->
      $scope.ui.article = res.data
      $scope.ui.article.release_time = moment($scope.ui.article.release_time).format 'YYYY-MM-DD'

      $scope.ui.article.data.content = $scope.ui.article.data.content.replace(/\"http\:\/\/mmbiz.qpic.cn/g, '"' + config.api.baseUrl + '/imgserve?_url=http://mmbiz.qpic.cn').replace(/\(http\:\/\/mmbiz.qpic.cn/g, '(' + config.api.baseUrl + '/imgserve?_url=http://mmbiz.qpic.cn').replace(/\&quot;http\:\/\/mmbiz.qpic.cn/g, '&quot;' + config.api.baseUrl + '/imgserve?_url=http://mmbiz.qpic.cn').replace(/\"https\:\/\/mmbiz.qpic.cn/g, '"' + config.api.baseUrl + '/imgserve?_url=https://mmbiz.qpic.cn').replace(/\(https\:\/\/mmbiz.qpic.cn/g, '(' + config.api.baseUrl + '/imgserve?_url=https://mmbiz.qpic.cn').replace(/\&quot;https\:\/\/mmbiz.qpic.cn/g, '&quot;' + config.api.baseUrl + '/imgserve?_url=https://mmbiz.qpic.cn')

      # $scope.ui.article.data.content = $scope.ui.article.data.content.replace(/<iframe/g, '<video controls="controls"')
      # $scope.ui.article.data.content = $scope.ui.article.data.content.replace(/<\/iframe/g, '</video')


      _content = _.clone $scope.ui.article.data.content
      _content = $("<div>#{_content}</div>").html()

      $rows = $("<p>#{_content}</p>")

      $scope.ui.content_html = $sce.trustAsHtml $scope.ui.article.data.content if $rows.find('iframe').toArray().length == 0

      _.each $rows.find('iframe').toArray(), (data, i) ->
        $http.get "#{config.api.baseUrl}/cumpus/programa/list/video",
          params:
            url: $(data).data('src')
        .then (res) ->
          _oldHtml3 = _.unescape $('<p>').append($(data).clone()).html()
          _src = $(data).data('src')
          $(data).removeAttr('data-src')
          _oldHtml = _.unescape $('<p>').append($(data).clone()).html()
          if data.src
            _oldHtml2 = _oldHtml.replace(data.src, "#{config.api.baseUrl}#{res.data.url}")
          _newHtml = _.unescape $('<p>').append($(data).clone()).html()
          $scope.ui.article.data.content = $scope.ui.article.data.content.replace(_oldHtml3, "<p style='text-align: center;'>#{_oldHtml2}</p>") if data.src
          $scope.ui.content_html = $sce.trustAsHtml $scope.ui.article.data.content
    .catch (error) ->


  do findDatas = ->
    $http.get "#{config.api.baseUrl}/cumpus/programa/list",
      params:
        is_show: true
        programa_id: $stateParams.columnId
        group_id: config.user.group_id
        sort: '-release_time'
        end_release_time: String moment().add('d', 1)
        limit: 10
        offset: (Number($stateParams.page) - 1) * 10
    .then (res) ->
      $scope.ui.totalItems = res.data.count
      $scope.ui.datas = res.data.data
      _.each $scope.ui.datas, (val) ->
        val.release_time = moment(val.release_time).format 'YYYY/MM/DD'

  $scope.toArticle = (article) ->
    $state.go('app.article', {columnId: article.programa_id, articleId: article._id, page: 1})


