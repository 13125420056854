angular.module 'app'

  .constant 'config',
    api:
      baseUrl: '/api'

    webapp:
      baseUrl: 'http://campus-app.ersinfotech.com/'

    user:
      group_id: 131
