angular.module 'app'
.controller 'TextArticleCtrl', ($sce, $scope, $state, $stateParams, activeUser, $http, config) ->
  $scope.ui =
    article: {}
    totalItems: 0
    datas: []
    previous: {}
    next: {}
    isPrevious: false
    isNext: false
  do findColumn = ->
    $http.get "#{config.api.baseUrl}/cumpus/programa/#{$stateParams.columnId}", {}
    .then (res) ->
      $scope.ui.column = res.data
    .catch (error) ->

  do findArticle = ->
    $http.get "#{config.api.baseUrl}/cumpus/programa/list/#{$stateParams.articleId}",
      params:
        is_content: true
        programa_id: $stateParams.columnId
        group_id: config.user.group_id
    .then (res) ->
      $scope.ui.article = res.data
      $scope.ui.article.release_time = moment($scope.ui.article.release_time).format 'YYYY-MM-DD'
      $scope.ui.content_html = $sce.trustAsHtml $scope.ui.article.data.content
    .catch (error) ->


  do findDatas = ->
    $http.get "#{config.api.baseUrl}/cumpus/programa/list",
      params:
        is_show: true
        programa_id: $stateParams.columnId
        group_id: config.user.group_id
        sort: '-release_time'
        end_release_time: String moment().add('d', 1)
        limit: 10
        offset: (Number($stateParams.page) - 1) * 10
    .then (res) ->
      $scope.ui.totalItems = res.data.count
      $scope.ui.datas = res.data.data
      _.each $scope.ui.datas, (val) ->
        val.release_time = moment(val.release_time).format 'YYYY/MM/DD'

  $scope.toArticle = (article) ->
    $state.go('app.article', {columnId: article.programa_id, articleId: article._id, page: 1})


