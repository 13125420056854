angular.module 'app'
.controller 'AdsCtrl', ($window, $scope, $state, activeUser, $http, config) ->
  $scope.ui =
    baseUrl: config.api.baseUrl
    ads: []

  do findData = ->
    $http.get "#{config.api.baseUrl}/cumpus/ad",
      params:
        limit: 4
        offset: 0
        group_id: config.user.group_id
        sort: 'sort'
        is_show: true
    .then (res) ->
      $scope.ui.ads = res.data.data
    .catch (error) ->

  $scope.toAd = (ad) ->

    if ad.type is 'link'
      $window.open(ad.link)
    else
      $state.go('app.ad', {adId: ad._id})

