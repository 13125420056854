angular.module 'app'
.controller 'LinksCtrl', ($window, $scope, $state, $stateParams, activeUser, $http, config) ->
  $scope.ui =
    baseUrl: config.api.baseUrl
    columnName: $stateParams.columnName
    currentPage: 1
    perPage: 10
    maxSize: 8
    totalItems: 0
    datas: []

  findDatas = ->
    $http.get "#{config.api.baseUrl}/cumpus/programa/list",
      params:
        is_show: true
        programa_id: $stateParams.columnId
        group_id: config.user.group_id
        sort: '-release_time'
        end_release_time: String moment().add('d', 1)
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
    .then (res) ->

      $scope.ui.totalItems = res.data.count
      $scope.ui.datas = res.data.data
      _.each $scope.ui.datas, (val) ->
        val.release_time = moment(val.release_time).format 'YYYY-MM-DD'

    .catch (error) ->

  $scope.$watch 'ui.currentPage', findDatas

  $scope.toLink = (article) ->
    $window.open(article.data.link)
