angular.module 'app'
.controller 'ColumnLayoutCtrl', ($window, $scope, $state, activeUser, $http, config) ->
  $scope.ui =
    baseUrl: config.api.baseUrl
    homePage: activeUser.homepage
    bottomColumns: []
    myInterval: 5000

  findArticles = (column, index) ->
    option =
      is_show: true
      programa_id: column._id
      group_id: config.user.group_id
      sort: '-release_time'
      offset: 0
      limit: 6
    if column.type isnt '3'
      option.end_release_time = String moment().add('d', 1)
    $http.get "#{config.api.baseUrl}/cumpus/programa/list",
      params: option
    .then (res) ->
      articles = res.data.data
      _.each articles, (val) ->
        val.release_time = moment(val.release_time).format 'YYYY/MM/DD'
      column.articles = articles
      if index is 2
        $scope.ui.slides = articles
    .catch (error) ->

  findBottomColumnsData = ->
    _.each $scope.ui.bottomColumns, (column, index) ->
      findArticles(column, index)

  do findColumns = ->
    if $scope.ui.homePage.layouts?.length > 0
      _.each $scope.ui.homePage.layouts, (layout) ->
        if layout.is_useing is true
          $scope.ui.centerColumns = layout.centerColumns
          $scope.ui.bottomColumns = layout.bottomColumns
          findBottomColumnsData()

  $scope.toColumn = (column) ->
    # 文章列表(1) 單文章/專題(2) 相冊(3) 外部鏈接(4)

    if column.type is '1'
      $state.go('app.articles', {columnId: column._id, columnName: column.programa_name})
    else if column.type is '2'
      $state.go('app.single-article', {columnId: column._id})
    else if column.type is '3'
      $state.go('app.images', {columnId: column._id, columnName: column.programa_name})
    else if column.type is '4'
      $window.open(column.data.link, "_self")
      return true
    else if column.type is '5'
      $state.go('app.text-articles', {columnId: column._id, columnName: column.programa_name})
    else if column.type is '6'
      $state.go('app.links', {columnId: column._id, columnName: column.programa_name})

  $scope.toArticle = (article, index) ->
    if article.type is 'link'
      $window.open(article.data.link)
    else
      $state.go('app.article', {columnId: article.programa_id, articleId: article._id, page: 1})

