angular.module 'app'
.controller 'HeaderCtrl', ($window, $scope, $state, activeUser, $http, config) ->
  $scope.ui =
    homePage: activeUser.homepage
    dynamicPopover:
      qrcodeImg: ''
      templateUrl: 'qrcodePopoverTemplate.html'
    headMenus: []
    centerColumns: []
    q: ''
    query: ''
    datas: []
    isShow: false

  if $scope.ui.homePage.qr_code?.length > 0
    $scope.ui.dynamicPopover.qrcodeImg = "#{config.api.baseUrl}#{$scope.ui.homePage.qr_code}"

  do findColumns = ->
    if $scope.ui.homePage.layouts?.length > 0
      _.each $scope.ui.homePage.layouts, (layout) ->
        if layout.is_useing is true
          $scope.ui.headMenus = layout.headMenus
          $scope.ui.centerColumns = layout.centerColumns

  $scope.toColumn = (column) ->
    # 文章列表(1) 單文章/專題(2) 相冊(3) 外部鏈接(4)

    if column.type is '1'
      $state.go('app.articles', {columnId: column._id, columnName: column.programa_name})
    else if column.type is '2'
      $state.go('app.single-article', {columnId: column._id})
    else if column.type is '3'
      $state.go('app.images', {columnId: column._id, columnName: column.programa_name})
    else if column.type is '4'
      $window.open(column.data.link)
    else if column.type is '5'
      $state.go('app.text-articles', {columnId: column._id, columnName: column.programa_name})
    else if column.type is '6'
      $state.go('app.links', {columnId: column._id, columnName: column.programa_name})

  $scope.toNew = (article) ->
    $scope.ui.isShow = false
    if article.is_head is false
      if article.type is 'link'
         $window.open(article.data.link)
      else
        $state.go('app.article', {columnId: article.programa_id, articleId: article._id, page: 1})
    else
      if article.type is 'link'
         $window.open(article.link)
      else
        $state.go('app.new', {newId: article._id})

  findDatas = ->
    if $scope.ui.q.length is 0
      $scope.ui.datas = []
      $scope.ui.isShow = false
      return
    $http.get "#{config.api.baseUrl}/cumpus/programa/list",
      params:
        is_show: true
        group_id: config.user.group_id
        sort: '-release_time'
        q: $scope.ui.q
        limit: 8
        offset: 0
        end_release_time: String moment().add('d', 1)
    .then (res) ->
      $scope.ui.datas = res.data.data
      if $scope.ui.datas.length > 0
        $scope.ui.isShow = true

    .catch (error) ->

  $scope.findDatas = findDatas

  $scope.$watch 'ui.q', findDatas

  $scope.search = ->
    if $scope.ui.q.length isnt 0
      $state.go('app.search', {q: $scope.ui.q})


  $scope.toOpen = ->
    if $scope.ui.datas.length > 0
      $scope.ui.isShow = true


