angular.module 'app'
.controller 'RightNavbarCtrl', ($scope, $state, activeUser, $http, config) ->
  $scope.ui =
    homePage: activeUser.homepage
    centerColumns: []

  do findColumns = ->
    if $scope.ui.homePage.layouts?.length > 0
      _.each $scope.ui.homePage.layouts, (layout) ->
        if layout.is_useing is true
          $scope.ui.centerColumns = layout.centerColumns

  $scope.toColumn = (column) ->
    # 文章列表(1) 單文章/專題(2) 相冊(3) 外部鏈接(4)

    if column.type is '1'
      $state.go('app.articles', {columnId: column._id, columnName: column.programa_name})
    else if column.type is '2'
      $state.go('app.single-article', {columnId: column._id})
    else if column.type is '3'
      $state.go('app.images', {columnId: column._id, columnName: column.programa_name})
    else if column.type is '4'
      window.open(column.data.link)
    else if column.type is '5'
      $state.go('app.text-articles', {columnId: column._id, columnName: column.programa_name})
    else if column.type is '6'
      $state.go('app.links', {columnId: column._id, columnName: column.programa_name})
