angular.module 'app'
.controller 'TopNewsCtrl', ($window, $scope, $state, activeUser, $http, config) ->
  $scope.ui =
    baseUrl: config.api.baseUrl
    slides: []
    interval: 4000

  do findData = ->
    $http.get "#{config.api.baseUrl}/cumpus/headline",
      params:
        limit: 8
        offset: 0
        group_id: config.user.group_id
        sort: 'sort'
        is_show: true
    .then (res) ->
      $scope.ui.slides = res.data.data

      _.each $scope.ui.slides, (val) ->
        val.created_at = moment(val.created_at).format 'YYYY-MM-DD HH:MM:SS'
    .catch (error) ->

  $scope.toNew = (article) ->
    if article.type is 'link'
      $window.open(article.link)
    else
      $state.go('app.new', {newId: article._id})

  $scope.selectImg = (slide) ->
    slide.active = true
    _.each $scope.ui.slides, (val) ->
      if val._id isnt slide._id
        val.active = false

